import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PageBanner from '../../../../components/utils/PageBanner';

const useStyles = makeStyles((theme) => ({
  separate: {
    padding: 24,
  },
  table: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  background: {
    backgroundColor: theme.palette.background.typography,
  },
  button: {
    float: 'right',
    margin: '0 24px 24px 0',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 16,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: 12,
  },
}));

export default function FineGrainedPermissions() {
  const classes = useStyles();

  return (
    <Box>
      <PageBanner title="FINE GRAINED PERMISSIONS" />
      <Grid item xs={12} className={classes.separate}>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="fine grained permissions table">
            <TableHead>
              <TableRow>
                <TableCell>Permission Name</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody />
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
