// QUERIES__________________________
export const listDeployments = /* GraphQL */ `
  query ListDeployments ($projectId: Int!, $page: Int, $pageSize: Int, $sortDirection: String, $fileType: String, $deleted: Int!) {
    listDeployments(projectId: $projectId, page: $page,  pageSize: $pageSize,  sortDirection: $sortDirection, fileType: $fileType, deleted: $deleted) {
      ... on ListDeploymentsSuccess {
        __typename
        data {
          deploymentID
          dateTime
          actor
          file {
            type
            fileID
            dateTime
            chunks
            content
            hashList
            hash
            manufacturerSignature
            softwareRequirements
            hardwareRequirements
            region
            metaData {
              major
              minor
              build
              hash
              version
              timestampToApply
              raw
            }
            assetDescription
          }
          target
          targetRegex
          targetMarket
          userCreation
          creationTimestamp
          exclude
          excludeMarket
          deleteTime
          deployDescription {
            userDescription
            userName
          }
        }
        pagination {
          totalRows
          isLast
          pageNumber
          pageRows
          pageSize
          totalPages
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;

export const getDeployment = /* GraphQL */ `
  query GetDeployment ($projectId: Int!, $deploymentID: String!) {
    getDeployment(projectId: $projectId, deploymentID: $deploymentID) {
      ... on GetDeploymentSuccess {
        __typename
        result {
          deploymentID
          dateTime
          actor
          file {
            type
            fileID
            dateTime
            chunks
            content
            hashList
            hash
            manufacturerSignature
            softwareRequirements
            hardwareRequirements
            region
            metaData {
              major
              minor
              build
              hash
              version
              timestampToApply
              raw
            }
            assetDescription
          }
          target
          targetRegex
          targetMarket
          userCreation
          creationTimestamp
          exclude
          excludeMarket
          deleteTime
          deployDescription {
            userDescription
            userName
          }
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;

export const getBundles = /* GraphQL */ `
  query GetBundles(
  $projectId: Int!,
  $sortDirection: String,
  $fileType: String,
  $page: String,
  $pageSize: String,
  $deleted: String,
  $releaseDescriptionValue: String,
  $releaseDescriptionKey: String
) {
  getBundles(
    projectId: $projectId,
    sortDirection: $sortDirection,
    fileType: $fileType,
    page: $page,
    pageSize: $pageSize,
    deleted: $deleted,
    releaseDescriptionValue: $releaseDescriptionValue,
    releaseDescriptionKey: $releaseDescriptionKey
  ) {
      ... on GetBundlesSuccess {
      __typename
        pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
        result {
          releaseDescription {
          description
        }
        title,
          fileIDs {
          assetDescription,
            fileID,
            dateTime,
            type,
          },
        region,
          brand,
          dateTime,
          releaseID
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
    }
      ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getBundle = /* GraphQL */ `
  query GetBundle($projectId: Int!, $releaseID: String!) {
  getBundle(projectId: $projectId, releaseID: $releaseID) {
      ... on GetBundleSuccess {
      __typename
        result {
          releaseDescription {
          description
        }
        title,
          fileIDs {
          assetDescription,
            fileID,
            dateTime,
            type,
          },
        region,
          brand,
          dateTime,
          releaseID
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
    }
      ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getBundleDeployments = /* GraphQL */ `
  query GetBundleDeployments($projectId: Int!, $releaseID: String!) {
  getBundleDeployments(projectId: $projectId, releaseID: $releaseID) {
      ... on GetBundleDeploymentsSuccess {
      __typename
        result {
        deployID
        dateTime
        actor
        file {
          type
          fileID
          dateTime
          chunks
          hashList
          hash
          softwareRequirements
          hardwareRequirements
          typeRequirements {
            hardwareRequirements
            softwareRequirements
            type
        }
          region
        }
        targetMarket
        target
        excludeMarket
          deployDescription {
          userDescription
          userName
        }
        releaseID
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
    }
      ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getBundleFileTypes = /* GraphQL */ `
  query GetBundleFileTypes($projectId: Int!) {
  getBundleFileTypes(projectId: $projectId) {
      ... on GetBundleFileTypes {
      __typename
      result
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
    }
      ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getPermissions = /* GraphQL */ `
  query GetPermissions($projectId: Int!, $rawPermissions: String!) {
  getFrontendPermissions(projectId: $projectId, rawPermissions: $rawPermissions) {
      ... on GetFrontendPermissionsSuccess {
      __typename
      permissions
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }  
      ... on Forbidden {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getMachineTelemetryConsumptionCSV = /* GraphQL */ `
  query GetMachineTelemetryConsumptionCSV($projectId: Int!, $serialNumber: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $user: String!, $jwtToken: String!) {
  getMachineTelemetryConsumptionCSV(projectId: $projectId, serialNumber: $serialNumber, dateIni: $dateIni, dateEnd: $dateEnd, user: $user, jwtToken: $jwtToken) {
      ... on GetMachineTelemetryConsumptionCSVSuccess {
      __typename
      success
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getMachineTelemetryConsumption = /* GraphQL */ `
  query GetMachineTelemetryConsumption($projectId: Int!, $serialNumber: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $limit: Int!, $offset: Int!) {
  getMachineTelemetryConsumption(projectId: $projectId, serialNumber: $serialNumber, dateIni: $dateIni, dateEnd: $dateEnd, limit: $limit, offset: $offset) {
      ... on GetMachineTelemetryConsumptionSuccess {
      __typename
        machineTelemetryConsumption {
        serialNumber
        market
        eventTimestamp
        serverTimestamp
        eventType
        code
        description
        type
        version
      }
        machineInfo {
        deviceFw
          extraDeviceFw {
          field
          value
        }
          modules {
          serial
          moduleFw
            extraDeviceFw {
            field
            value
          }
        }
        gatewayApp
      }
        pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getBrandConsumptions = /* GraphQL */ `
query GetBrandConsumptions($projectId: Int!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $limit: Int!, $offset: Int!) {
  getBrandConsumptions(projectId: $projectId, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, limit: $limit, offset: $offset) {
    ... on GetBrandConsumptionsSuccess {
      __typename
      brandConsumptions {
        id
        deviceSerial
        moduleSerial
        countryCode
        recipeCode
        productCode
        customerCode
        isValidTime
        extra
        createdOnLocal
        createdOnUtc
        receivedOnUtc
        processedOnUtc
        dictProduct{
          code
          market
          name
          insertedOn
          updatedOn
          isDeleted
        }
        dictRecipe{
          code
          market
          name
          extra
          insertedOn
          updatedOn
          isDeleted
        }
      }
      pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getBrandConsumptionsCSV = /* GraphQL */ `
query GetBrandConsumptionsCSV($projectId: Int!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $user: String!, $jwtToken: String!) {
  getBrandConsumptionsCSV(projectId: $projectId, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, user: $user, jwtToken: $jwtToken) {
    ... on GetBrandConsumptionsCSVSuccess {
      __typename
      success
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const listAssetsNotDeleted = /* GraphQL */ `
  query ListAssetsNotDeleted($projectId: Int!, $page: Int, $pageSize: Int, $sortDirection: String, $type: String, $assetDescriptionValue: String, $assetDescriptionKey: String) {
  listAssetsNotDeleted(projectId: $projectId, page: $page, pageSize: $pageSize, sortDirection: $sortDirection, type: $type, assetDescriptionValue: $assetDescriptionValue, assetDescriptionKey: $assetDescriptionKey) {
      ... on ListAssetsNotDeletedSuccess {
      __typename
        pagination {
        totalRows
        isLast
        pageNumber
        pageRows
        pageSize
        totalPages
      }
        data {
        chunks
        assetDescription
          metaData {
          build
          hash
          major
          minor
          raw
          timestampToApply
          version
        }
        content
        creationTimestamp
        dateTime
        fileID
        hardwareRequirements
        hash
        hashList
        manufacturerSignature
        region
        softwareRequirements
        type
        typeRequirements {
          hardwareRequirements
          softwareRequirements
          type
        }
        userCreation
        releaseIDs
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getAsset = /* GraphQL */ `
  query GetAsset($projectId: Int!, $fileId: String!) {
  getAsset(projectId: $projectId, fileId: $fileId) {
      ... on GetAssetSuccess {
      __typename
        data {
        chunks
        assetDescription
        dateTime
          metaData {
          build
          hash
          major
          minor
          raw
          timestampToApply
          version
        }
        content
        creationTimestamp
        fileID
        hardwareRequirements
        hash
        hashList
        manufacturerSignature
        region
        softwareRequirements
        type
        typeRequirements {
          hardwareRequirements
          softwareRequirements
          type
        }
        userCreation
        releaseIDs
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getAssetTypes = /* GraphQL */ `
query getAssetTypes($projectId: Int!) {
  getAssetTypes(projectId: $projectId) {
    ... on GetAssetTypesSuccess {
      __typename
      response {
        data
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getDeploymentFileTypes = /* GraphQL */ `
query getAssetTypes($projectId: Int!) {
  getDeploymentFileTypes(projectId: $projectId) {
    ... on GetDeploymentFileTypesSuccess {
      __typename
      response {
        data
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getTelemetryObject = /* GraphQL */ `
query GetTelemetryObject($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $limit: Int!, $offset: Int!, $datesRange: Int!) {
  getTelemetryObject(projectId: $projectId, object: $object, queryParams: $queryParams, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, limit: $limit, offset: $offset, datesRange: $datesRange) {
    ... on GetTelemetryObjectSuccess {
      __typename
      data
      pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getTelemetryObjectCSV = /* GraphQL */ `
query GetTelemetryObjectCSV($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $datesRange: Int!, $user: String!, $jwtToken: String!) {
  getTelemetryObjectCSV(projectId: $projectId, object: $object, queryParams: $queryParams, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, datesRange: $datesRange, user: $user, jwtToken: $jwtToken) {
    ... on GetTelemetryObjectCSVSuccess {
      __typename
      success
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getLifeCycleObject = /* GraphQL */ `
query GetLifeCycleObject($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime, $paginationToken: String) {
  getLifeCycleObject(projectId: $projectId, object: $object, queryParams: $queryParams, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, paginationToken: $paginationToken) {
    ... on GetLifeCycleObjectSuccess {
      __typename
      data
      pagination {
        paginationContinuationToken
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getLifeCycleObjectPaginated = /* GraphQL */ `
query GetLifeCycleObject($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime, $page: Int, $pageSize: Int) {
  getLifeCycleObjectPaginated(
    projectId: $projectId, 
    object: $object, 
    queryParams: $queryParams, 
    filters: $filters, 
    dateIni: $dateIni, 
    dateEnd: $dateEnd, 
    page: $page, 
    pageSize: $pageSize
  ) {
    ... on GetLifeCycleObjectPaginatedSuccess {
      __typename
      data
      pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getLifeCycleObjectCSV = /* GraphQL */ `
query GetLifeCycleObjectCSV($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $datesRange: Int!, $user: String!, $jwtToken: String!) {
  getLifeCycleObjectCSV(
    projectId: $projectId, 
    object: $object, 
    queryParams: $queryParams, 
    filters: $filters, 
    dateIni: $dateIni, 
    dateEnd: $dateEnd, 
    datesRange: $datesRange, 
    user: $user, 
    jwtToken: $jwtToken) {
    ... on GetLifeCycleObjectCSVSuccess {
      __typename
      success
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSRFfunctions = /* GraphQL */ `
query GetSRFfunctions($projectId: Int!) {
  getSRFfunctions(projectId: $projectId) {
    ... on GetSRFfunctionsSuccess {
      __typename
      result {
        functionUniqueName
        minSecure
        sourceId
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSRFBackends = /* GraphQL */ `
query GetSRFBackends($projectId: Int!) {
  getSRFBackends(projectId: $projectId) {
    ... on GetSRFBackendsSuccess {
      __typename
      result {
        algorithm
        brand
        sourceId
        privateKeyExists
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSRFToken = /* GraphQL */ `
query GetSRFToken($payload: String!, $algorithm: String!, $privateKey: String!) {
  getSRFToken(payload: $payload, algorithm: $algorithm, privateKey: $privateKey) {
    ... on GetSRFTokenSuccess {
      __typename
      result
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSandboxObject = /* GraphQL */ `
query GetSandboxObject($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $datesRange: Int!, $paginationToken: String) {
  getSandboxObject(projectId: $projectId, object: $object, queryParams: $queryParams, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, datesRange: $datesRange, paginationToken: $paginationToken) {
    ... on GetSandboxObjectSuccess {
      __typename
      data
      pagination {
        paginationContinuationToken
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSandboxObjectCSV = /* GraphQL */ `
query GetSandboxObjectCSV($projectId: Int!, $object: String!, $queryParams: String!, $filters: String!, $dateIni: AWSDateTime!, $dateEnd: AWSDateTime!, $datesRange: Int!, $user: String!, $jwtToken: String!) {
  getSandboxObjectCSV(projectId: $projectId, object: $object, queryParams: $queryParams, filters: $filters, dateIni: $dateIni, dateEnd: $dateEnd, datesRange: $datesRange, user: $user, jwtToken: $jwtToken) {
    ... on GetSandboxObjectCSVSuccess {
      __typename
      success
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getProjects = /* GraphQL */ `
  query GetProjects {
    getProjects {
      ... on GetProjectsSuccess {
      __typename
        projects {
        code
        id
        brandId
        permission
        name
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getActionsBetweenDates = /* GraphQL */ `
query GetActionsBetweenDates($from: AWSDateTime!, $to: AWSDateTime!, $page: Int, $limit: Int!) {
  getActionsBetweenDates(from: $from, to: $to, page: $page, limit: $limit) {
    ... on GetActionsSuccess {
      __typename
      data {
        actionTimestamp
        id
        entityType
        entityId
        user
        actionType
        cognitoEmail
        actionDetails
      }
      pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getActionsBetweenDatesWithUser = /* GraphQL */ `
query GetActionsBetweenDates($from: AWSDateTime!, $to: AWSDateTime!, $user: String!, $page: Int, $limit: Int!) {
  getActionsBetweenDates(from: $from, to: $to, user: $user, page: $page, limit: $limit) {
    ... on GetActionsSuccess {
      __typename
      data {
        actionTimestamp
        id
        entityType
        entityId
        user
        actionType
        actionDetails
        cognitoEmail
      }
      pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getActionStatistics = /* GraphQL */ `
query GetActionStatistics($from: AWSDateTime!, $to: AWSDateTime!, $projectId: Int) {
  getActionStatistics(from: $from, to: $to, projectId: $projectId) { 
    ... on GetActionStatisticsSuccess {
      __typename
      data
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getCognitoUsersGroupsCSV = /* GraphQL */ `
  query GetCognitoUsersGroupsCSV {
    getCognitoUsersGroupsCSV {
      ... on GetCognitoUsersGroupsCSVSuccess {
      __typename
      csvData
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getNotifications = /* GraphQL */ `
  query GetNotifications($user: String!) {
  getNotifications(user: $user) {
      ... on NotificationsSuccess {
      __typename
        notifications {
        id
        title
        description
        type
        read
        timestamp
        user
        user_email
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getConfig = /* GraphQL */ `
  query GetConfig {
    getConfig {
      ... on GetConfigSuccess {
      __typename
      data
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getUnreadNotificationsCount = /* GraphQL */ `
  query GetUnreadNotificationsCount($user: String!) {
  getUnreadNotificationsCount(user: $user) {
      ... on UnreadNotificationsCountSuccess {
      __typename
      count
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getUnreadNotifications = /* GraphQL */ `
  query GetUnreadNotifications($projectId: Int!, $user: String!) {
  getUnreadNotifications(projectId: $projectId, user: $user) {
      ... on NotificationsSuccess {
      __typename
        notifications {
        id
        title
        description
        type
        read
        timestamp
        user
        user_email
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

// MUTATIONS_________________________

export const uploadAssetsFromPackage = /* GraphQL */ `
  mutation UploadAssetsFromPackage($projectId: Int!, $filePath: String!) {
  uploadAssetsFromPackage
    (
      projectId: $projectId,
      filePath: $filePath,
    ) {
        ... on UploadAssetsFromPackageSuccess {
      __typename
          list{
        typeDescription
        fileID
        error
      }
    }
        ... on BadRequest {
      __typename
      message
      statusCode
    }
        ... on Forbidden {
      __typename
      message
      statusCode
    }
        ... on InternalServerError {
      __typename
      message
      statusCode
    }
        ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const createNonGlobalDeploy = /* GraphQL */ `
  mutation CreateNonGlobalDeploy(
  $projectId: Int!,
  $fileIDS: [String],
  $releaseID: String,
  $type: DeploymentType!,
  $value: [String],
  $comments: String,
  $deployRegEx: [String],
  $user: String) {
  createNonGlobalDeploy
    (
      projectId: $projectId,
      createDeploymentInput: {
      fileIDS: $fileIDS,
      releaseID: $releaseID,
      type: $type,
      value: $value,
      comments: $comments,
      deployRegEx: $deployRegEx,
      user: $user,
    }
    ) {
        ... on DeploymentOutputSuccess {
      __typename
          deployResult {
        deployID
        waveId
        deployIDS
        newDeployIDs
        alreadyExistingDeployIDs
      }
    }
        ... on Forbidden {
      __typename
      message
      statusCode
    }          
        ... on BadRequest {
      __typename
      message
      statusCode
    }
        ... on InternalServerError {
      __typename
      message
      statusCode
    }
        ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const createGlobalDeploy = /* GraphQL */ `
  mutation CreateGlobalDeploy(
  $projectId: Int!,
  $fileIDS: [String],
  $releaseID: String,
  $type: DeploymentType!,
  $value: [String],
  $comments: String,
  $deployRegEx: [String],
  $user: String,
  $target: String,
  $targetMarket: String,
  $exclude: [String],
  $excludeMarket: [String]
) {
  createGlobalDeploy
    (
      projectId: $projectId,
      createDeploymentInput: {
      fileIDS: $fileIDS,
      releaseID: $releaseID,
      type: $type,
      value: $value,
      comments: $comments,
      deployRegEx: $deployRegEx,
      user: $user,
      target: $target,
      targetMarket: $targetMarket,
      exclude: $exclude,
      excludeMarket: $excludeMarket
    }
    ) {
        ... on DeploymentOutputSuccess {
      __typename
          deployResult {
        deployID
        waveId
        deployIDS
        newDeployIDs
        alreadyExistingDeployIDs
      }
    }
        ... on Forbidden {
      __typename
      message
      statusCode
    }          
        ... on BadRequest {
      __typename
      message
      statusCode
    }
        ... on InternalServerError {
      __typename
      message
      statusCode
    }
        ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const deleteDeploy = /* GraphQL */ `
  mutation DeleteDeploy($projectId: Int!, $deployId: String!) {
  deleteDeploy(
    projectId: $projectId,
    deployId: $deployId,
  ) {
          ... on DeleteDeploySuccess {
      __typename
      response
    }
          ... on Forbidden {
      __typename
      message
      statusCode
    }          
          ... on BadRequest {
      __typename
      message
      statusCode
    }
          ... on InternalServerError {
      __typename
      message
      statusCode
    }
          ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const deleteBundle = /* GraphQL */ `
  mutation DeleteBundle($projectId: Int!, $releaseID: String!) {
  deleteBundle(
    projectId: $projectId, releaseID: $releaseID,
  ) {
      ... on DeleteBundleSuccess {
      __typename
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }          
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const deleteAsset = /* GraphQL */ `
mutation DeleteAsset($projectId: Int!, $deleteAssetInput: [DeleteAssetInput]!) {
  deleteAsset(
    projectId: $projectId,
    deleteAssetInput: $deleteAssetInput,
  ) {
      ... on DeleteAssetSuccess {
      __typename
        response {
        message
        statusCode
      }
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }          
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const updateAssetDescription = /* GraphQL */ `
  mutation UpdateAssetDescription($projectId: Int!, $fileId: String!, $assetDescription: String!) {
    updateAssetDescription(assetDescription: $assetDescription, fileId: $fileId, projectId: $projectId) {
      ... on UpdateAssetDescriptionSuccess {
        __typename
        response
      }
    }
}
`;

export const createNotification = /* GraphQL */ `
  mutation CreateNotification($title: String, $description: String, $user: String!, $type: String, $userEmail: String, $jwtToken: String) {
    createNotification(title: $title, description: $description, user: $user, type: $type, userEmail: $userEmail, jwtToken: $jwtToken) {
      ... on NotificationSuccess {
        __typename
          notification {
          id
          title
          description
          type
          read
          timestamp
          user
          user_email
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
}
`;

export const markAsReadMutation = /* GraphQL */ `
  mutation MarkAsRead($id: String!) {
    markAsRead(id: $id) {
      ... on MarkNotificationSuccess {
        __typename
        response
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
}
`;

export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($id: String!) {
    deleteNotification(id: $id) {
      ... on DeleteNotificationSuccess {
        __typename
        response
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
}
`;

export const triggerTestsExecutions = /* GraphQL */ `
  mutation TriggerTestsExecutions($tests: [String], $brands: [Int]) {
    triggerTestsExecutions(tests: $tests, brands: $brands) {
      ... on TriggerTestsExecutionsSuccess {
        __typename
        result
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
}
`;

export const triggerHealthCalculation = /* GraphQL */ `
  mutation TriggerHealthCalculation {
    triggerHealthCalculation {
      ... on TriggerHealthCalculationSuccess {
        __typename
        result
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const onNewNotificationItem = /* GraphQL */ `
  subscription OnNewNotificationItem {
    onNewNotificationItem {
      ... on NotificationSuccess {
      __typename
        notification {
        description
        id
        read
        timestamp
        title
        type
        user
        user_email
      }
    }
      ... on BadRequest {
      __typename
      statusCode
      message
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const onUpdateConfig = /* GraphQL */ `
  subscription OnUpdateConfig {
    onUpdateConfig {
      ... on UpdateConfigSuccess {
      __typename
      success
    }
      ... on BadRequest {
      __typename
      statusCode
      message
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig($config: String!) {
  updateConfig(config: $config) {
      ... on UpdateConfigSuccess {
      __typename
      success
    }
      ... on BadRequest {
      __typename
      statusCode
      message
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const createBundle = /* GraphQL */ `
  mutation CreateBundle($projectId: Int!, $release: NewBundle!) {
  createBundle(projectId: $projectId, release: $release) {
      ... on CreateBundleSuccess {
      __typename
      result {
        releaseID
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const updateBundle = /* GraphQL */ `
  mutation UpdateBundle($projectId: Int!, $releaseID: String!, $title: String!, $description: String!) {
    updateBundle(projectId: $projectId, releaseID: $releaseID, title: $title, description: $description) {
      ... on UpdateBundleSuccess {
        __typename
        result # Boolean field, no subselection allowed
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
        statusCode
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const executeSRF = /* GraphQL */ `
  mutation ExecuteSRF($projectId: Int!, $machineId: String!, $functionName: String!, $securityMode: String!, $jwtToken: String!) {
  executeSRF(projectId: $projectId, machineId: $machineId, functionName: $functionName, securityMode: $securityMode, jwtToken: $jwtToken) {
      ... on ExecuteSRFSuccess {
      __typename
      result {
        payload {
          reqId
          status
          exp
          sub
          aud
          iat
        }
        encodedToken
        header
      }

    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const cancelSRF = /* GraphQL */ `
  mutation CancelSRF($projectId: Int!, $reqId: String!) {
  cancelSRF(projectId: $projectId, reqId: $reqId) {
      ... on CancelSRFSuccess {
      __typename
      result
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getSRFRequest = /* GraphQL */ `
  query GetSRFRequest($projectId: Int!, $reqId: String!) {
  getSRFRequest(projectId: $projectId, reqId: $reqId) {
    ... on GetSRFRequestSuccess {
      __typename
      result {
        payload {
          reqId
          status
          exp
          sub
          aud
          iat
          lastChange
          iss
          results {
            res
            now
          }
        }
        encodedToken
        header
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on Forbidden {
      __typename
      message
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getSRFRequests = /* GraphQL */ `
  query GetSRFRequests($projectId: Int!, $machineId: String!) {
  getSRFRequests(projectId: $projectId, machineId: $machineId) {
    ... on GetSRFRequestsSuccess {
      __typename
      result {
        expiration
        functionId
        functionName
        lastChange
        retry
        secure
        sourceId
        status
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on Forbidden {
      __typename
      message
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getCertificateQuota = /* GraphQL */ `
  query GetCertificateQuota($awsUserId: String!) {
  getCertificateQuota(awsUserId: $awsUserId) {
    ... on GetCertificateQuotaSuccess {
      __typename
      result {
        quota
        restrictedUser
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on Forbidden {
      __typename
      message
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const getCertificateStatus = /* GraphQL */ `
  query GetCertificateStatus($certificateId: String!) {
  getCertificateStatus(certificateId: $certificateId) {
    ... on GetCertificateStatusSuccess {
      __typename
      result {
        certificateID
        certificateARN
        caCertificateID
        status
        policies {
          policyName
          policyArn
        }
        onboardingStatus
      }
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on Forbidden {
      __typename
      message
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
    }
  }
}
`;

export const deleteNotificationsByTitle = /* GraphQL */ `
  mutation DeleteNotificationsByTitle($title: String!) {
  deleteNotificationsByTitle(title: $title) {
    ... on DeleteNotificationSuccess {
      __typename
      response
    }
    ... on BadRequest {
      __typename
      message
      statusCode
    }
    ... on Forbidden {
      __typename
      message
      statusCode
    }
    ... on InternalServerError {
      __typename
      message
      statusCode
    }
    ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const deleteWave = /* GraphQL */ `
  mutation DeleteWave($projectId: Int!, $waveID: String!) {
  deleteWave(projectId: $projectId, waveID: $waveID) {
      ... on DeleteWaveSuccess {
      __typename
        result {
        deletedDeployIDs
        maintainedDeployIDs
        deletedWaveID
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const updateWave = /* GraphQL */ `
  mutation UpdateWave($projectId: Int!, $waveID: String!, $waveDescription: String!) {
  updateWave(projectId: $projectId, waveID: $waveID, waveDescription: $waveDescription) {
      ... on UpdateWaveSuccess {
      __typename
        result {
        waveID
        dateTime
        target
        targetMarket
        excludeMarket
        target
        targetRegex
        excludeRegex
          waveDescription {
          userDescription
        }
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const listPaginatedWaves = /* GraphQL */ `
  query ListPaginatedWaves(
  $projectId: Int!,
  $page: Int,
  $pageSize: Int,
  $sortDirection: String,
  $datetimeFrom: String,
  $datetimeTo: String,
  $waveDescriptionKey: String,
  $waveDescriptionValue: String,
  $fileType: String,
  $fileID: String,
  $releaseID: String,
  $deployID: String
) {
  listPaginatedWaves(
    projectId: $projectId,
    page: $page,
    pageSize: $pageSize,
    sortDirection: $sortDirection,
    datetimeFrom: $datetimeFrom,
    datetimeTo: $datetimeTo,
    waveDescriptionKey: $waveDescriptionKey,
    waveDescriptionValue: $waveDescriptionValue,
    fileType: $fileType,
    fileID: $fileID,
    releaseID: $releaseID,
    deployID: $deployID
  ) {
      ... on ListPaginatedWavesSuccess {
      __typename
        data {
        waveID
        dateTime
        targetMarket
        excludeMarket
        target
        targetRegex
        excludeRegex
          waveDescription {
          userDescription
        }
          release {
            releaseDescription {
            description
          }
          title
          region
            fileIDs {
            type
            fileID
            dateTime
            chunks
            manufacturerSignature
            softwareRequirements
            hardwareRequirements
            region
          }
          brand
          dateTime
          releaseID
        }
          deploys {
          deployID
          dateTime
          actor
            file {
            type
            fileID
            dateTime
            chunks
              assetDescription {
              version
              description
            }
          }
            deployDescription {
            userDescription
            userName
          }
        }
      }
        pagination {
        totalRows
        pageNumber
        pageSize
        pageRows
        totalPages
        isLast
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getWaveById = /* GraphQL */ `
  query GetWaveById($projectId: Int!, $waveID: String!) {
  getWaveById(projectId: $projectId, waveID: $waveID) {
      ... on GetWaveByIdSuccess {
      __typename
        result {
        waveID
        dateTime
        targetMarket
        excludeMarket
        target
        targetRegex
        excludeRegex
          waveDescription {
          userDescription
        }
          release {
            releaseDescription {
            description
          }
          title
          region
            fileIDs {
            type
            fileID
            dateTime
            chunks
            manufacturerSignature
            softwareRequirements
            hardwareRequirements
            region
          }
          brand
          dateTime
          releaseID
        }
          deploys {
          deployID
          dateTime
          actor
            file {
            type
            fileID
            dateTime
            chunks
              assetDescription {
              version
              description
            }
          }
            deployDescription {
            userDescription
            userName
          }
        }
      }
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const onboardingDevice = /* GraphQL */ `
  mutation OnBoardingDevice($projectId: Int!, $serialNumber: String!, $topicNumber: Int!,$payload: String!) {
  onboardingDevice(
    projectId: $projectId, 
    serialNumber: $serialNumber, 
    topicNumber: $topicNumber,
    payload: $payload) {
      ... on OnboardingDeviceSuccess {
      __typename
        result
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getIotMessages = /* GraphQL */ `
  query GetIotMessages($projectId: Int!, $serialNumber: String!, $topicNumber: Int!) {
  getIotMessages(projectId: $projectId, serialNumber: $serialNumber, topicNumber: $topicNumber) {
      ... on GetIotMessagesSuccess {
      __typename
        result
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const subscribeToTopic = /* GraphQL */ `
  mutation SubscribeToTopic($projectId: Int!, $serialNumber: String!, $topicNumber: Int!) {
  subscribeToTopic(projectId: $projectId, serialNumber: $serialNumber, topicNumber: $topicNumber) {
      ... on SubscribeToTopicSuccess {
      __typename
        result
    }
      ... on BadRequest {
      __typename
      message
      statusCode
    }
      ... on InternalServerError {
      __typename
      message
      statusCode
    }
      ... on Forbidden {
      __typename
      message
      statusCode
    }
      ... on NotFound {
      __typename
      message
      statusCode
    }
  }
}
`;

export const getPlatformHealth = /* GraphQL */ `
  query GetPlatformHealth($brands: [Int!], $services: [String!], $date: AWSDateTime) {
    getPlatformHealth(brands: $brands, services: $services, date: $date) {
      ... on GetPlatformHealthSuccess {
        __typename
        result {
            PlatformHealth {
              Overall {
                health
                healthPercentage
                failureRatio
                date
                e2eTestsUpdatedAt
                healthUpdatedAt
                lastNonGoodHealth {
                  health
                  healthPercentage
                  healthUpdatedAt
                }
              }
              Service {
                service
                failureRatio
                health
                healthPercentage
                healthUpdatedAt
                failureDetails {
                  type
                  name
                  criticality
                  errorCategory
                  brandImportance
                  serviceRelevance
                  errorFrequency
                  errorSeverity
                }
                links {
                  tests
                  alarms
                }
              }
          }
          BrandHealth {
              Brand
              Overall {
                health
                healthPercentage
                failureRatio
                date
                e2eTestsUpdatedAt
                healthUpdatedAt
                lastNonGoodHealth {
                  health
                  healthPercentage
                  healthUpdatedAt
                }
              }
              Service {
                service
                failureRatio
                health
                healthPercentage
                healthUpdatedAt
                failureDetails {
                  type
                  name
                  criticality
                  errorCategory
                  brandImportance
                  serviceRelevance
                  errorFrequency
                  errorSeverity
                }
                links {
                  tests
                  alarms
                }
              }
          }
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;

export const getServicesHealthHistory = /* GraphQL */ `
  query GetServicesHealthHistory($services: [String], $from: AWSDateTime, $to: AWSDateTime) {
    getServicesHealthHistory(services: $services, from: $from, to: $to) {
      ... on GetServicesHealthHistorySuccess {
        __typename
        result {
          ServiceHealthHistory {
            Service
            History {
              date
              health
              healthPercentage
              failureRatio
              e2eTestsUpdatedAt
              healthUpdatedAt
            }
          }
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;

export const getServicesHealthHistoryByBrands = /* GraphQL */ `
  query GetServicesHealthHistoryByBrands($brands: [Int], $services: [String], $from: AWSDateTime, $to: AWSDateTime) {
    getServicesHealthHistoryByBrands(brands: $brands, services: $services, from: $from, to: $to) {
      ... on GetServicesHealthHistoryByBrandsSuccess {
        __typename
        result {
          BrandsHealthHistory {
            Brand
              PlatformHealthHistory {
                date
                health
                healthPercentage
                failureRatio
                e2eTestsUpdatedAt
                healthUpdatedAt
            }
          } 
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;

export const getTestsExecutions = /* GraphQL */ `
  query GetTestsExecutions($service: String, $date: AWSDateTime) {
    getTestsExecutions(service: $service, date: $date) {
      ... on GetTestsExecutionsSuccess {
        __typename
        result {
          test_id
          e2e_execution_id
          title
          suite
          environment
          criticality
          brand
          error_category
          brand_importance
          service_brand_relevance
          error_frequency
          error_severity
          result
          started_at
          duration
          logs
          logsPresignedUrl
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
      ... on Forbidden {
        __typename
        message
      }
      ... on InternalServerError {
        __typename
        message
        statusCode
      }
      ... on NotFound {
        __typename
        message
      }
    }
  }
`;
